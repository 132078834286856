@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Barlow Condensed";
  src: url("/static/fonts/BarlowCondensed-Regular.ttf") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Roboto Flex";
  src: url("/static/fonts/RobotoFlex-Regular.ttf") format("woff2");
  font-display: swap;
}

html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto Flex", sans-serif;
  font-size: 14px;
  color: #454545;
  font-weight: 300;
  letter-spacing: 0.034rem;
  line-height: 1.2rem;
}

::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 20px;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ff0037;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

h1 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.62rem;
  letter-spacing: 0.001rem;
  line-height: 2.1rem;
  font-weight: 600;
}

h2 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.38rem;
  letter-spacing: 0.001rem;
  line-height: 1.6rem;
  font-weight: 600;
}
h3 {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.02rem;
  line-height: 1.65rem;
  font-weight: 600;
}

p {
  font-family: "Roboto Flex", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.038rem;
  line-height: 1.2rem;
}

.slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border-radius: 5px;
  background: #13b60a;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #4caf50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
  overflow-wrap: break-word;
}

#map {
  height: 100%;
}

#__next {
  min-height: 100% !important;
  position: relative;
}

.fit {
  min-height: calc(100vh - 88px);
}

.next-image-wrapper > span {
  height: 100% !important;
}

.reset-this * {
  font-size: revert;
  font-weight: revert;
  font-family: revert;
  font-style: revert;
}

.ql-toolbar {
  -webkit-border-top-left-radius: 12px;
  -webkit-border-top-right-radius: 12px;
  -moz-border-radius-topleft: 12px;
  -moz-border-radius-topright: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.ql-container {
  -webkit-border-bottom-right-radius: 12px;
  -webkit-border-bottom-left-radius: 12px;
  -moz-border-radius-bottomright: 12px;
  -moz-border-radius-bottomleft: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

[type="text"]:focus {
  box-shadow: inset 0px 0px 0px 0px white;
}

.select-container input {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  box-shadow: inset 0px 0px 0px 0px white;
}

.select-container input:focus {
  box-shadow: inset 0px 0px 0px 0px white;
}

.break-word {
  word-break: break-word;
}

#mymap {
  background: url(/static/images/gMaps.webp);
  background-size: auto;
  overflow: hidden;
}

#mymap iframe {
  border: 0;
}

iframe {
  max-width: 100% !important;
}

.blurred-image {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

#ad-card-slot {
  min-height: 240px;
  min-width: 280px;
}

#ad-article-slot {
  min-height: 280px;
  min-width: 280px;
}

.ql-editor > p {
  margin-top: 1.3333333em !important;
  margin-bottom: 1.3333333em !important;
}

.ql-editor > ul {
  margin-top: 1.3333333em !important;
  margin-bottom: 1.3333333em !important;
}

.ql-editor > ul > li {
  margin-top: 0.6666667em;
  margin-bottom: 0.6666667em;
}

.react-datepicker-wrapper {
  width: 100%;
}

img,
video {
  max-width: 100%;
  height: 100%;
}
